/*-------- 8. Footer style  -----*/

.footer-area {
  position: relative;
  &.ml-10 {
    margin-left: 0;
  }
  @media #{$xl-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 30px;
    }
    &.mr-70 {
      margin-right: 30px;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 40px;
    }
    &.mr-70 {
      margin-right: 40px;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 15px;
    }
    &.mr-70 {
      margin-right: 15px;
    }
  }
}
