/*------- 6. Product style  --------*/

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        color: #fff;
        display: block;
        line-height: 1;
        padding: 3px 11px;
        border-radius: 3px;
        font-weight: 500;
        margin-bottom: 10px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 20px);
      transition-duration: 0.7s;
    }
    .product-action {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      z-index: 9;
      transform: translateX(-50%);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > div {
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        transform: translateY(20px);
        background-color: $theme-color;
        transition: all 0.5s ease;
      }
      .pro-wishlist {
        width: 48px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;
        width: calc(100% - 48px - 48px);
        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0;
        transition-delay: 0.2s;
      }
      .pro-same-action {
        a,
        button {
          color: #fff;
          font-size: 16px;
          height: 48px;
          line-height: 48px;
          display: block;
          width: 100%;
          text-align: center;
          text-transform: capitalize;
          background: none;
          border: none;
          &:hover,
          &.active {
            background-color: #000;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    margin: 20px 0 0;
    h3 {
      font-size: 16px;
      margin: 0;
    }
    .product-rating {
      margin: 3px 0 3px;
      i {
        font-size: 17px;
        color: #5f5d5d;
        margin: 0 3px;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      span {
        font-size: 15px;
        font-weight: 500;
        margin: 0 9px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          right: -13px;
          top: 10px;
          width: 7px;
          height: 2px;
          background-color: #777;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          color: #8e8e8e;
          text-decoration: line-through;
        }
      }
    }
  }
  &:hover .hover-img {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }
  &:hover .product-action > div {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.product-details-content {
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 0;
      margin-top: 30px;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h2 {
    color: #010101;
    font-size: 24px;
    margin: 0 0 0px;
    line-height: 1;
  }
  .product-details-price {
    display: flex;
    align-items: center;
    margin: 15px 0 26px;
    span {
      font-size: 24px;
      &.old {
        font-size: 18px;
        text-decoration: line-through;
        margin-left: 20px;
      }
      &.new {
        color: #fe5252;
      }
    }
  }
  .pro-details-rating-wrap {
    display: flex;
    align-items: center;
    margin: 0 0 17px;
    .pro-details-rating {
      position: relative;
      margin-right: 39px;
      &:before {
        position: absolute;
        right: -19px;
        top: 4px;
        content: "";
        height: 15px;
        width: 2px;
        background-color: #d1d1d1;
        display: none;
      }
      i {
        color: #5f5d5d;
        font-size: 17px;
        margin-right: 5px;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    span {
      a {
        font-size: 15px;
        color: $theme-color;
        &:hover {
          color: #000;
        }
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 28px;
    color: #333;
    margin: 0;
  }
  .pro-details-list {
    margin: 20px 0 34px;
    border-bottom: 1px solid #bbc0c6;
    padding: 0 0 37px;
    ul {
      li {
        color: #333;
        margin: 0 0 5px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .pro-details-size-color {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .pro-details-color-wrap {
      margin-right: 20px;
      @media #{$xs-layout} {
        margin-bottom: 20px;
      }
      @media #{$sm-layout} {
        margin-bottom: 0px;
      }
      span {
        display: block;
        font-weight: 500;
        margin: 0 0 15px;
        font-size: 15px;
      }
      .pro-details-color-content {
        ul {
          li {
            border-radius: 50px;
            cursor: pointer;
            display: block;
            float: left;
            height: 14px;
            margin-right: 15px;
            position: relative;
            transition: all 0.4s ease 0s;
            width: 14px;
            &:last-child {
              margin-right: 0px;
            }
            &.blue {
              background-color: #4798f3;
              border: 1px solid #4798f3;
            }
            &.maroon {
              background-color: #736751;
              border: 1px solid #736751;
            }
            &.gray {
              background-color: #c0c0c0;
              border: 1px solid #c0c0c0;
            }
            &.green {
              background-color: #139c57;
              border: 1px solid #139c57;
            }
            &.yellow {
              background-color: #e28b37;
              border: 1px solid #e28b37;
            }
          }
        }
        &--single {
          position: relative;
          border-radius: 50px;
          cursor: pointer;
          display: inline-block;
          height: 14px;
          width: 14px;
          margin-right: 15px;
          transition: all 0.4s ease 0s;
          &:last-child {
            margin-right: 0;
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 100%;

            &:checked {
              & ~ .checkmark {
                &:after {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
          &.blue {
            background-color: #4798f3;
            border: 1px solid #4798f3;
          }
          &.maroon {
            background-color: #736751;
            border: 1px solid #736751;
          }
          &.gray {
            background-color: #c0c0c0;
            border: 1px solid #c0c0c0;
          }
          &.green {
            background-color: #139c57;
            border: 1px solid #139c57;
          }
          &.yellow {
            background-color: #e28b37;
            border: 1px solid #e28b37;
          }
          &.white {
            background-color: #fff;
            border: 1px solid #333;
          }
          &.black {
            background-color: #333;
            border: 1px solid #333;
          }
          &.brown {
            background-color: brown;
            border: 1px solid brown;
          }
          .checkmark {
            margin: 0;
            height: 14px;
            width: 14px;
            position: relative;
            display: inline-block;
            &:after {
              position: absolute;
              top: -7px;
              left: -4px;
              width: 20px;
              height: 20px;
              border: 2px solid #333;
              border-radius: 50%;
              content: "";
              visibility: hidden;
              opacity: 0;
              transition: 0.3s;
            }
          }

          &:hover {
            input {
              & ~ .checkmark {
                &:after {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .pro-details-size {
      span {
        display: block;
        font-weight: 500;
        margin: 0 0 10px;
        font-size: 15px;
      }
      .pro-details-size-content {
        ul {
          li {
            display: inline-block;
            list-style: outside none none;
            margin: 0 2px 0 0;
            a {
              background-color: #f1f2f6;
              color: #000;
              display: inline-block;
              font-size: 12px;
              line-height: 1;
              padding: 6px 9px 7px;
              text-transform: uppercase;
              &:hover {
                background-color: $theme-color;
                color: #fff;
              }
            }
          }
        }
        &--single {
          margin: 0 5px 0 0;
          background-color: #f1f2f6;
          color: #000;
          display: inline-block;
          font-size: 12px;
          line-height: 1;
          text-transform: uppercase;
          position: relative;
          &:hover {
            background-color: $theme-color;
            color: #fff;
          }
          input {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            cursor: pointer;
            height: 100%;

            &:checked {
              & ~ .size-name {
                background-color: $theme-color;
                color: $white;
              }
            }
          }
          .size-name {
            margin-bottom: 0;
            width: 100%;
            padding: 8px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .pro-details-quality {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 34px;
    .cart-plus-minus {
      border: 1px solid #e8e8e8;
      display: inline-block;
      height: 25px;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 80px;
      .qtybutton {
        color: #8f8f8f;
        cursor: pointer;
        float: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        position: absolute;
        text-align: center;
        transition: all 0.3s ease 0s;
        width: 24px;
        background: none;
        border: none;
      }
      .dec.qtybutton {
        height: 25px;
        left: 0;
        top: 0;
      }
      .inc.qtybutton {
        height: 25px;
        right: 0;
        top: 0;
      }
      input.cart-plus-minus-box {
        background: transparent none repeat scroll 0 0;
        border: medium none;
        color: #8f8f8f;
        float: left;
        font-size: 14px;
        height: 25px;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 80px;
      }
    }
    .pro-details-cart {
      margin: 0 25px 0 10px;
      @media #{$xs-layout} {
        margin: 0 10px 0 10px;
      }
      @media #{$md-layout} {
        margin: 0 17px 0 10px;
      }
      a,
      button {
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        background: none;
        border: none;
        background-color: #343538;
        display: inline-block;
        line-height: 1;
        padding: 23px 38px 23px;
        @media #{$xs-layout} {
          padding: 23px 12px 23px;
        }
        @media #{$md-layout} {
          padding: 22px 22px 22px;
        }
        z-index: 99;
        &:hover {
          border: none;
        }

        &:disabled {
          cursor: not-allowed;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    .pro-details-wishlist {
      a,
      button {
        font-size: 18px;
        color: #000;
        background: none;
        border: none;
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
    .pro-details-compare {
      a,
      button {
        font-size: 18px;
        color: #000;
        margin-left: 25px;
        background: none;
        border: none;
        @media #{$xs-layout} {
          margin-left: 10px;
        }
        &:hover,
        &.active {
          color: $theme-color;
        }
      }
    }
  }
  .pro-details-meta {
    display: flex;
    margin: 0 0 0px;
    span {
      font-size: 13px;
      margin-right: 5px;
    }
    ul {
      li {
        display: inline-block;
        margin: 0 10px 0 0;
        a {
          color: #676767;
          font-size: 15px;
          @media #{$xs-layout} {
            font-size: 14px;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .pro-details-social {
    margin: 24px 0 0;
    ul {
      display: flex;
      li {
        margin-right: 40px;
        &:last-child {
          margin-right: 0px;
        }
        a {
          font-size: 16px;
          color: #343538;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &.pro-details-slider-content {
    @media #{$xs-layout} {
      &.mt-50 {
        margin-top: 30px;
      }
    }
    .product-details-price {
      justify-content: center;
    }
    p {
      width: 59%;
      margin: 0 auto;
      @media #{$lg-layout} {
        width: 80%;
      }
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$xs-layout} {
        width: 100%;
      }
    }
    .pro-details-rating-wrap {
      justify-content: center;
    }
    .pro-details-size-color {
      justify-content: center;
      text-align: left;
      @media #{$xs-layout} {
        text-align: center;
        & .pro-details-color-content {
          justify-content: center;
          display: flex;
        }
      }
    }
    .pro-details-quality {
      justify-content: center;
    }
    .pro-details-meta {
      justify-content: center;
    }
    .pro-details-social {
      ul {
        justify-content: center;
      }
    }
  }
}

/*  prodduct 2     */

.product-filter-wrapper {
  background-color: #fff;
  overflow: hidden;
  height: 0;
  transition: height 0.4s ease-out;

  .product-filter {
    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 25px;
      padding-bottom: 7px;
      position: relative;
      text-transform: capitalize;
      &::before {
        background-color: #000;
        bottom: 0;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 20px;
      }
    }
    ul.sort-by {
      li {
        margin-bottom: 3px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
        }
      }
    }
    ul.color-filter {
      li {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0px;
        }
        input {
          width: 20px;
          height: 20px;
          display: inline-block;
          float: left;
          margin: 3px 8px 0 0;
        }
        a {
          text-transform: capitalize;
        }
      }
    }
    .product-tags {
      ul {
        li {
          margin-bottom: 4px;
          a {
            text-transform: capitalize;
          }
        }
      }
    }

    ul {
      li {
        button {
          background: none;
          border: none;
          text-transform: capitalize;
          &:hover,
          &.active {
            color: $theme-color;
          }
        }
      }
    }

    &--tag {
      ul {
        li {
          display: inline-block;
          button {
            text-transform: lowercase;
            border: 1px solid #e6e6e6;
            margin-right: 10px;
            margin-bottom: 10px;
            &:hover {
              border-color: $theme-color;
            }
          }
        }
      }
    }
  }

  &__inner {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
    padding: 45px 45px 7px;
    @media #{$md-layout} {
      padding: 35px 25px 7px;
    }
    @media #{$xs-layout} {
      padding: 35px 25px 7px;
    }
  }
}
