/*------ 22. contact Page  ------*/

.contact-map {
  height: 560px;
  position: relative;
  @media #{$xs-layout} {
    height: 400px;
  }
}

.contact-info-wrap {
  background-color: #f3f3f3;
  padding: 120px 70px 116px 90px;
  @media #{$lg-layout} {
    padding: 120px 20px 116px 40px;
  }
  @media #{$md-layout} {
    padding: 120px 20px 116px 30px;
  }
  @media #{$xs-layout} {
    padding: 50px 20px 45px 30px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .single-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .contact-icon {
      margin-right: 20px;
      i {
        font-size: 20px;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 100%;
        transition: all 0.3s ease 0s;
      }
    }
    .contact-info-dec {
      p {
        line-height: 1;
        margin: 0 0 9px;
        a {
          &:hover {
            color: $theme-color;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    &:hover .contact-icon i {
      background-color: #252525;
      color: #fff;
    }
  }
}

.contact-info-wrap2 {
  padding: 10px 10px 10px 10px;
  @media #{$lg-layout} {
    padding: 10px 10px 10px 10px;
  }
  @media #{$md-layout} {
    padding: 10px 10px 10px 10px;
  }
  @media #{$xs-layout} {
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .single-contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .contact-icon {
      margin-right: 20px;
      i {
        font-size: 20px;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 100%;
        transition: all 0.3s ease 0s;
      }
    }
    .contact-info-dec {
      p {
        line-height: 1;
        margin: 0 0 9px;
        a {
          &:hover {
            color: $theme-color;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    &:hover .contact-icon i {
      background-color: #252525;
      color: #fff;
    }
  }
}
