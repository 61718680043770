/* === Icon fonts === */
@import "~ti-icons/css/themify-icons.css";
/* === Date Picker === */
@import "~react-datepicker/dist/react-datepicker.css";

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body.light-mode {
  // background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}

body.dark-mode {
  // background-color: #10171d;
  background-color: rgb(48, 47, 47);
  color: #dfdfdf;

  .navbar {
    background-color: #1b2938;
  }

  .dark-mode-toggle > button {
    color: #999;
    &:last-child {
      color: lightblue;
    }
  }

  h1 {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  h2 {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  h3 {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  h4 {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  h5 {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }
  span {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  i {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  Link {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  header {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  p {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  a {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }

  nav {
    background-color: rgb(48, 47, 47);
    color: #dfdfdf;
  }
}
