@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "header";
@import "slider";
@import "product";
@import "blog";
@import "footer";
@import "about";
@import "image-slider";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "contact";
@import "404";
