.blog-wrap {
  .blog-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      transform: scale(1);
      transition: all 0.5s ease 0s;
    }
    .blog-category-names {
      position: absolute;
      top: 10px;
      left: 10px;
      span {
        display: inline-block;
        line-height: 1;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        padding: 2px 10px 5px;
        margin-right: 10px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: $theme-color;
        }
        &.red {
          background-color: #c61a32;
        }

        &::last-child {
          margin-right: 0;
        }
      }
      &--style2 {
        right: 10px;
        left: auto;
      }
    }
  }
  .blog-content-wrap {
    text-align: center;
    .blog-content {
      display: inline-block;
      background-color: #fff;
      padding: 14px 44px;
      margin: -64px 0 0;
      z-index: 99;
      position: relative;
      width: 320px;
      max-width: 100%;
      @media #{$lg-layout} {
        padding: 14px 30px;
        margin: -50px 0 0;
      }
      @media #{$xs-layout} {
        padding: 14px 30px;
        margin: -50px 0 0;
      }
      @media #{$sm-layout} {
        padding: 14px 15px;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin: 0 0 5px;
        @media #{$sm-layout} {
          font-size: 18px;
          line-height: 27px;
        }
        a {
        }
      }
      span {
        color: #666;
        font-style: italic;
        a {
          color: #666;
          &:hover {
            color: $theme-color;
          }
        }
      }

      &--style2 {
        background: #f6f3f3;
        width: 100%;
        min-height: 150px;
        padding: 21px 15px 30px 15px;
        margin-top: 10px;
      }
    }
  }
  &:hover .blog-img img {
    transform: scale(1.2);
  }
}

/*-------- 7. Blog style  ----------*/

.Blog-area {
  @media #{$lg-layout} {
    .ml-20 {
      margin-left: 0;
    }
    .mr-20 {
      margin-right: 0;
    }
  }
  @media #{$md-layout} {
    .ml-20 {
      margin-left: 0;
    }
    .mr-20 {
      margin-right: 0;
    }
  }
  @media #{$xs-layout} {
    .ml-20 {
      margin-left: 0;
    }
    .mr-20 {
      margin-right: 0;
    }
    .pro-pagination-style.mt-20 {
      margin-top: 0;
    }
  }
}
.Blog-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 45px;
    }
  }
  &.blog-no-sidebar {
    .blog-content-2 {
      h4 {
        font-size: 20px;
        @media #{$xs-layout} {
          font-size: 17px;
        }
        @media #{$sm-layout} {
          font-size: 20px;
        }
      }
      .blog-share-comment {
        .blog-share {
          .share-social {
            @media #{$lg-layout} {
              margin-top: 4px;
            }
            ul {
              li {
                @media #{$lg-layout} {
                  margin-right: 3px;
                }
                a {
                  @media #{$lg-layout} {
                    font-size: 12px;
                    height: 25px;
                    line-height: 23px;
                    width: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
