.menu-item {
  padding: 0 4px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
  align-self: start;
}
.menu-item-wrapper.active {
  /* border-bottom: 1px black solid; */
}
.menu-item.active {
  /* border: 1px green solid; */
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.small-menu-item {
  padding: 0 4px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
  align-self: start;
}
.small-menu-item-wrapper.active {
  /* border-bottom: 1px black solid; */
  /* background-color: blueviolet; */
}
.small-menu-item.active {
  /* border: 1px green solid; */
}

.small-scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}
