.error {
  h1 {
    font-size: 17rem;
    font-weight: bold;
    letter-spacing: 1rem;
    line-height: 1.1;
    margin-bottom: 30px;
    color: #a749ff;
    @media #{$lg-layout} {
      font-size: 15rem;
      margin-bottom: 4px;
    }
    @media #{$md-layout} {
      font-size: 12rem;
    }
    @media #{$xs-layout} {
      font-size: 7rem;
      line-height: 88px;
    }
    @media #{$sm-layout} {
      font-size: 9rem;
      line-height: 100px;
    }
  }
  h2 {
    font-size: 25px;
    font-weight: 600;
  }
}
