/*----- 19. Product details page  ---*/

.single-review {
  display: flex;
  margin: 0 0 50px;
  @media #{$xs-layout} {
    display: block;
  }
  @media #{$sm-layout} {
    display: flex;
  }
  .review-img {
    flex: 0 0 90px;
    margin: 0 15px 0 0;
    @media #{$xs-layout} {
      margin: 0 0px 20px 0;
    }
    @media #{$sm-layout} {
      margin: 0 15px 0px 0;
    }
  }
  .review-top-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    align-items: center;
    .review-left {
      display: flex;
      .review-name {
        margin-right: 25px;
        h4 {
          font-size: 16px;
          margin: 0;
          line-height: 1;
          font-weight: 500;
        }
      }
      .review-rating {
        line-height: 1;
        i {
          font-size: 12px;
          margin-right: 1px;
          color: #ffa900;
        }
      }
      & > button {
        background: none;
        border: none;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .review-btn a {
      font-weight: 500;
      line-height: 1;
    }
  }
  .review-bottom p {
    margin: 0;
    width: 93%;
  }
}

.single-review.child-review {
  margin-left: 70px;
  margin-bottom: 0;
  @media #{$xs-layout} {
    margin-left: 0px;
  }
}

.ratting-form-wrapper {
  @media #{$md-layout} {
    &.pl-50 {
      padding-left: 0;
      margin-top: 50px;
    }
  }
  @media #{$xs-layout} {
    &.pl-50 {
      padding-left: 0;
      margin-top: 50px;
    }
  }
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
  .ratting-form {
    form {
      .star-box {
        display: flex;
        margin: 6px 0 20px;
        span {
          margin: 0 15px 0 0;
        }
        .ratting-star {
          font-size: 12px;
          color: #ffa900;
          margin: 2px 0 0;
        }
      }
      .rating-form-style {
        input,
        textarea {
          padding: 2px 10px 2px 20px;
          background: transparent;
          border: 1px solid #e6e6e6;
          color: #333;
        }
        textarea {
          height: 180px;
          padding: 20px 10px 2px 20px;
          margin-bottom: 20px;
        }
      }
      .form-submit input {
        width: auto;
        padding: 12px 50px;
        font-weight: 500;
        text-transform: uppercase;
        height: auto;
        background-color: $theme-color;
        color: #fff;
        border: 1px solid $theme-color;
        &:hover {
          background-color: #333;
          border: 1px solid #333;
        }
      }
    }
  }
}

.product-large-image-wrapper {
  position: relative;
  .product-img-badges {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
    span {
      font-size: 13px;
      color: #fff;
      display: block;
      line-height: 1;
      padding: 3px 11px;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 10px;
      &.pink {
        background-color: #fa6bff;
      }
      &.purple {
        background-color: #a749ff;
      }
    }
  }

  .react_lightgallery_item {
    position: absolute;
    top: 20px;
    right: 20px;
    button {
      background: none;
      border: none;
      font-size: 30px;
      padding: 0;
    }
  }

  &--slider {
    .ht-swiper-button-nav {
      background: none;
      border: none;
      font-size: 50px;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      color: #333;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
    }
  }
}

.shop-area {
  .sticky {
    top: 70px !important;
  }
}

.product-small-image-wrapper {
  .ht-swiper-button-nav {
    background: none;
    border: none;
    visibility: hidden;
    opacity: 0;
    color: #333;
    i {
      font-size: 40px;
    }
  }
  .single-image {
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;
      opacity: 1;
    }
  }
  .single-image {
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .swiper-container-vertical {
    height: 100%;
  }

  &--side-thumb {
    height: 630px;
    overflow: hidden;
    @media #{$lg-layout, $md-layout, $xs-layout} {
      height: auto;
      margin-top: 15px;
    }
  }
}
