/*----- 20. Cart page  -----*/

.cart-main-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-table-content {
  table {
    border: 1px solid #ebebeb;
    thead > tr {
      background-color: #f9f9f9;
      border: 1px solid #434141;
      th {
        border-top: medium none;
        color: #333;
        font-size: 14px;
        font-weight: 500;
        padding: 21px 45px 22px;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle;
        white-space: nowrap;
        @media #{$lg-layout} {
          padding: 21px 35px 22px;
        }
        @media #{$xs-layout} {
          padding: 21px 20px 22px;
        }
      }
    }
    tbody > tr {
      border-bottom: 1px solid #ebebeb;
      td.product-thumbnail {
        width: 150px;
      }
      td.product-name {
        width: 435px;
        text-align: left;
        a {
          color: #333;
          font-size: 15px;
          font-weight: 500;
          &:hover {
            color: $theme-color;
          }
        }
      }
      td.product-price-cart {
        width: 435px;
        span {
          font-weight: 500;
          color: #333;
          &.old {
            text-decoration: line-through;
            margin-right: 10px;
            color: #8e8e8e;
          }
        }
      }
      td.product-subtotal {
        font-weight: 500;
        color: #333;
      }
      td.product-quantity {
        width: 435px;
        .cart-plus-minus {
          display: inline-block;
          height: 40px;
          padding: 0;
          position: relative;
          width: 110px;
          .qtybutton {
            color: #333;
            cursor: pointer;
            float: inherit;
            background: none;
            border: none;
            font-size: 16px;
            margin: 0;
            position: absolute;
            transition: all 0.3s ease 0s;
            width: 20px;
            text-align: center;
          }
          .dec.qtybutton {
            border-right: 1px solid #e5e5e5;
            height: 40px;
            left: 0;
            top: 0;
          }
          .inc.qtybutton {
            border-left: 1px solid #e5e5e5;
            height: 40px;
            right: 0;
            top: 0;
          }
          input.cart-plus-minus-box {
            color: #333;
            float: left;
            font-size: 14px;
            height: 40px;
            margin: 0;
            width: 110px;
            background: transparent none repeat scroll 0 0;
            border: 1px solid #e1e1e1;
            padding: 0;
            text-align: center;
          }
        }
      }
      td.product-remove {
        width: 100px;
        a,
        button {
          color: #666;
          background: none;
          border: none;
          font-size: 17px;
          margin: 0 13px;
          &:hover {
            color: $theme-color;
          }
        }
      }
      td.product-wishlist-cart > a,
      td.product-wishlist-cart > button {
        background: none;
        border: none;
        background-color: $theme-color;
        border-radius: 50px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
        padding: 10px 15px;
        text-transform: uppercase;
        display: block;
        margin: 0 auto;
        &:hover,
        &.active {
          background-color: #333;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      td {
        color: #333;
        font-size: 15px;
        padding: 30px 0 30px 30px;
        text-align: center;
      }
    }
  }
}
