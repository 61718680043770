/*---- 18. Shop page  -------*/

.shop-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media #{$xs-layout} {
    display: block;
  }
  @media #{$sm-layout} {
    display: flex;
  }
  .select-shopping-wrap {
    display: flex;
    @media #{$xs-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: flex;
    }
    .shop-select {
      margin-right: 50px;
      select {
        border: 1px solid #e6e6e6;
        padding: 5px;
        font-size: 14px;
        color: #606060;
        min-width: 143px;
      }
    }
    p {
      color: #606060;
      margin: 0;
      @media #{$xs-layout} {
        margin: 5px 0 10px;
      }
      @media #{$sm-layout} {
        margin: 0px;
      }
    }
  }
  .shop-tab {
    a,
    button {
      color: #606060;
      font-size: 18px;
      margin-left: 20px;
      background: none;
      border: none;
      padding: 0;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: #fa6bff;
      }
    }
  }
}

.pro-pagination-style {
  @media #{$xs-layout} {
    &.mt-30 {
      margin-top: 10px;
    }
  }
  ul {
    li {
      display: inline-block;
      margin: 0 4px;
      a,
      button {
        display: inline-block;
        width: 43px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        font-size: 16px;
        background: none;
        border: none;
        border-radius: 100% !important;
        padding: 0;
        color: $theme-color;
        box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: $theme-color;
          color: #fff;
        }
      }
      a.active {
        background-color: $theme-color;
        color: #fff;
        box-shadow: none;
        &:hover {
          background-color: #333;
        }
      }
      a.prev,
      a.next {
        background-color: #f6f6f6;
        color: $theme-color;
        font-size: 17px;
        box-shadow: none;
        &:hover {
          background-color: $theme-color;
          color: #fff;
        }
      }

      &.page-item {
        &.active {
          .page-link {
            background-color: $theme-color;
            border-color: $theme-color;
            box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
            &:focus {
              box-shadow: none;
            }
          }
        }
        .page-link {
          box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.pro-pagination-style2 {
  @media #{$xs-layout} {
    &.mt-30 {
      margin-top: 10px;
    }
  }
  ul {
    li {
      display: inline-block;
      margin: 0 4px;
      a,
      button {
        display: inline-block;
        width: 43px;
        height: 43px;
        text-align: center;
        line-height: 43px;
        font-size: 16px;
        background: none;
        border: none;
        border-radius: 100% !important;
        padding: 0;
        color: $theme-color;
        box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: $theme-color;
          color: #fff;
        }
      }
      a.active {
        background-color: $theme-color;
        color: #fff;
        box-shadow: none;
        &:hover {
          background-color: #333;
        }
      }
      a.prev,
      a.next {
        background-color: #f6f6f6;
        color: $theme-color;
        font-size: 17px;
        box-shadow: none;
        &:hover {
          background-color: $theme-color;
          color: #fff;
        }
      }

      &.page-item {
        &.active {
          .page-link {
            background-color: $theme-color;
            border-color: $theme-color;
            box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
            &:focus {
              box-shadow: none;
            }
          }
        }
        .page-link {
          box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media #{$xs-layout} {
  .shop-area.pb-100 {
    padding-bottom: 45px;
  }
}

.shop-bottom-area {
  .col-xl-4 {
    transition: 0.5s;
  }
  .grid {
    .shop-list-wrap {
      display: none;
    }

    &.two-column {
      .col-xl-4 {
        flex: 1 0 50%;
        max-width: 50%;
        transition: 0.5s;
      }
    }
  }
  .list {
    .product-wrap {
      display: none;
    }
    &.two-column {
      & > .col-xl-4 {
        flex: 1 0 50%;
        max-width: 50%;
        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 100%;
          max-width: 100%;
        }
        @media #{$md-layout} {
          flex: 1 0 100%;
          max-width: 100%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    }

    & > .col-xl-4 {
      flex: 1 0 100%;
      max-width: 100%;
      transition: 0.5s;
    }
  }
}

.shop-area {
  .container-fluid {
    padding: 0 70px;
    @media #{$xl-layout} {
      padding: 0 15px;
    }
    @media #{$lg-layout} {
      padding: 0 30px;
    }
    @media #{$md-layout} {
      padding: 0 40px;
    }
    @media #{$xs-layout} {
      padding: 0 15px;
    }
  }
}
